var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"monitor-list"},[_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":_vm.handleTabs},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"企业列表","name":"company"}},[_c('AssetsTable',{attrs:{"isIndex":true,"data":_vm.companyData.data,"labelData":_vm.companyData.label,"page":_vm.companyData.page,"total":_vm.companyData.pageTotal},on:{"changePage":_vm.changePage},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var scope = ref.scope;
return [_c('router-link',{attrs:{"target":"_blank","to":{
              path: '/enterprise-details',
              query: { qn: scope.row.name },
            }}},[_c('el-button',{staticClass:"pa-0",attrs:{"type":"text"}},[_vm._v(_vm._s(scope.row.name))])],1)]}},{key:"code_id",fn:function(ref){
            var scope = ref.scope;
return [_c('el-button',{staticClass:"monitor-btn",attrs:{"loading":scope.row.monitor_load},on:{"click":function($event){return _vm.monitorBtn(scope.row.code_id)}}},[_vm._v("取消监控 ")])]}}])})],1),_c('el-tab-pane',{attrs:{"label":"专利列表","name":"patent"}},[_c('AssetsTable',{attrs:{"isIndex":true,"data":_vm.patentData.data,"labelData":_vm.patentData.label,"page":_vm.patentData.page,"total":_vm.patentData.pageTotal},on:{"changePage":_vm.changePage},scopedSlots:_vm._u([{key:"name",fn:function(ref){
            var scope = ref.scope;
return [_c('router-link',{attrs:{"target":"_blank","to":{
              path: '/patent-details',
              query: { pid: scope.row.code_id },
            }}},[_c('el-button',{staticClass:"pa-0",attrs:{"type":"text"}},[_vm._v(_vm._s(scope.row.name))])],1)]}},{key:"code_id",fn:function(ref){
            var scope = ref.scope;
return [_c('el-button',{staticClass:"monitor-btn",attrs:{"loading":scope.row.monitor_load},on:{"click":function($event){return _vm.monitorBtn(scope.row.code_id)}}},[_vm._v("取消监控 ")])]}}])})],1),_c('el-tab-pane',{attrs:{"label":"商标列表","name":"trademark"}},[_c('AssetsTable',{attrs:{"isIndex":true,"data":_vm.trademarkData.data,"labelData":_vm.trademarkData.label,"page":_vm.trademarkData.page,"total":_vm.trademarkData.pageTotal},on:{"changePage":_vm.changePage},scopedSlots:_vm._u([{key:"name",fn:function(ref){
            var scope = ref.scope;
return [_c('router-link',{attrs:{"target":"_blank","to":{
              path: '/trademark-details',
              query: { tid: scope.row.code_id },
            }}},[_c('el-button',{staticClass:"pa-0",attrs:{"type":"text"}},[_vm._v(_vm._s(scope.row.name))])],1)]}},{key:"code_id",fn:function(ref){
            var scope = ref.scope;
return [_c('el-button',{staticClass:"monitor-btn",attrs:{"loading":scope.row.monitor_load},on:{"click":function($event){return _vm.monitorBtn(scope.row.code_id)}}},[_vm._v("取消监控 ")])]}}])})],1)],1),_c('Search',{attrs:{"isStatus":true,"placeholder":"请输入监控名称","isRequired":false},on:{"searchBtn":_vm.searchBtn}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }