/** 商标 */
import request from '@/utils/request.js';

// 商标筛选条件
export const getTradeFilters = (keywords) => {
  return request({
    method: 'post',
    url: '/trademarkanalysis',
    data: {
      keywords
    }
  })
}

// 商标列表
export const getTradeList = (data) => {
  return request({
    method: 'post',
    url: '/trademark',
    headers: {
      token: true
    },
    data
  })
}

// 商标详情
export const getTradeDetails = (id) => {
  return request({
    method: 'post',
    url: '/trademarkinfo',
    headers: {
      token: true
    },
    data: {
      id
    }
  })
}

// 商标质押
export const getTradePledge = (id, page) => {
  return request({
    method: 'post',
    url: '/trademarkpledgedata',
    data: {
      id,
      page
    }
  })
}

// 商标监控
export const setTradeMonitor = (data) => {
  return request({
    method: 'post',
    url: '/usermonitortrademark',
    headers: {
      token: true
    },
    data
  })
}

// 企业商标图表
export const getEpTradeChart = (HNO) => {
  return request({
    method: 'post',
    url: '/trademarkyeargroup',
    data: {
      HNO
    }
  })
}