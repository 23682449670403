/** 企业 */
import request from '@/utils/request.js';

// 企业列表
export const getEpList = (keywords, page) => {
  return request({
    method: 'get',
    url: '/eyecheck',
    headers: {
      token: true
    },
    params: {
      keywords,
      page
    }
  })
}

// 企业列表监控
export const setEpMonitor = (data) => {
  return request({
    method: 'post',
    url: '/usermonitocompany',
    headers: {
      token: true
    },
    data
  })
}

// 企业详情
export const getEpDetails = (keywords) => {
  return request({
    method: 'get',
    url: '/search',
    headers: {
      token: true
    },
    params: {
      keywords
    }
  })
}

// 企业股东信息

export const getEpShareholders = (data)=>{
  return request({
    method: 'get',
    url: '/getshareholderlist',
    headers: {
      token: true
    },
    params: data
  })
}

// 企业专利列表
export const getEpPatent = (keywords, page, sort) => {
  return request({
    method: 'get',
    url: '/patents',
    params: {
      keywords,
      page,
      sort,
    }
  })
}

// 企业专利查询
export const getEpPatentSearch = (data) => {
  return request({
    method: 'post',
    url: '/company',
    data
  })
}
