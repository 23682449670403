/** 监控列表 */
<template>
  <div class="monitor-list">
    <el-tabs v-model="activeName" type="card" @tab-click="handleTabs">
      <!-- 企业列表 -->
      <el-tab-pane label="企业列表" name="company">
        <AssetsTable :isIndex="true" :data="companyData.data" :labelData="companyData.label" :page="companyData.page"
          :total="companyData.pageTotal" @changePage="changePage">
          <template v-slot:name="{ scope }">
            <router-link target="_blank" :to="{
                path: '/enterprise-details',
                query: { qn: scope.row.name },
              }">
              <el-button type="text" class="pa-0">{{
                scope.row.name
              }}</el-button>
            </router-link>
          </template>
          <template v-slot:code_id="{ scope }">
            <el-button class="monitor-btn" :loading="scope.row.monitor_load" @click="monitorBtn(scope.row.code_id)">取消监控
            </el-button>
          </template>
        </AssetsTable>
      </el-tab-pane>
      <!-- 专利列表 -->
      <el-tab-pane label="专利列表" name="patent">
        <AssetsTable :isIndex="true" :data="patentData.data" :labelData="patentData.label" :page="patentData.page"
          :total="patentData.pageTotal" @changePage="changePage">
          <template v-slot:name="{ scope }">
            <router-link target="_blank" :to="{
                path: '/patent-details',
                query: { pid: scope.row.code_id },
              }">
              <el-button type="text" class="pa-0">{{
                scope.row.name
              }}</el-button>
            </router-link>
          </template>
          <template v-slot:code_id="{ scope }">
            <el-button class="monitor-btn" :loading="scope.row.monitor_load" @click="monitorBtn(scope.row.code_id)">取消监控
            </el-button>
          </template>
        </AssetsTable>
      </el-tab-pane>
      <!-- 商标列表 -->
      <el-tab-pane label="商标列表" name="trademark">
        <AssetsTable :isIndex="true" :data="trademarkData.data" :labelData="trademarkData.label"
          :page="trademarkData.page" :total="trademarkData.pageTotal" @changePage="changePage">
          <template v-slot:name="{ scope }">
            <router-link target="_blank" :to="{
                path: '/trademark-details',
                query: { tid: scope.row.code_id },
              }">
              <el-button type="text" class="pa-0">{{
                scope.row.name
              }}</el-button>
            </router-link>
          </template>
          <template v-slot:code_id="{ scope }">
            <el-button class="monitor-btn" :loading="scope.row.monitor_load" @click="monitorBtn(scope.row.code_id)">取消监控
            </el-button>
          </template>
        </AssetsTable>
      </el-tab-pane>
    </el-tabs>
    <Search :isStatus="true" placeholder="请输入监控名称" :isRequired="false" @searchBtn="searchBtn" />
  </div>
</template>
<script>
import AssetsTable from "@/components/Assets/table.vue";
import Search from "@/components/Search.vue";
import { setItem, getItem } from "@/utils/storage";
import { getMonitorList } from "@/api/personal";
import { setEpMonitor } from "@/api/enterprise";
import { setTradeMonitor } from "@/api/trademark";
import { setPatentMonitor } from "@/api/patent";
export default {
  name: "MonitorList",
  components: {
    AssetsTable,
    Search,
  },
  data () {
    return {
      activeName: getItem("monitor_list_type", "sessionStorage") || "company",
      searchVal: "",
      companyData: {
        label: [
          { label: "企业名称", value: "name", width: "350px", isSlot: true },
          { label: "法定代表人", value: "legal_person" },
          { label: "企业状态", value: "company_status" },
          { label: "添加监控时间", value: "created_at", width: "118px" },
          { label: "操作", value: "code_id", width: "150px", isSlot: true },
        ],
        data: null,
        page: 1,
        pageTotal: 0,
      },
      patentData: {
        label: [
          { label: "专利名称", value: "name", width: "350px", isSlot: true },
          { label: "类型", value: "type" },
          { label: "法律状态", value: "patent_status" },
          { label: "添加监控时间", value: "created_at", width: "118px" },
          { label: "操作", value: "code_id", width: "150px", isSlot: true },
        ],
        data: null,
        page: 1,
        pageTotal: 0,
      },
      trademarkData: {
        label: [
          { label: "商标名称", value: "name", width: "350px", isSlot: true },
          { label: "国际分类", value: "nc_cn" },
          { label: "法律状态", value: "trademark_status" },
          { label: "添加监控时间", value: "created_at", width: "118px" },
          { label: "操作", value: "code_id", width: "150px", isSlot: true },
        ],
        data: null,
        page: 1,
        pageTotal: 0,
      },
    };
  },
  mounted () {
    this.getMonitorList();
  },
  methods: {
    // 获取监控列表
    async getMonitorList (page = 1) {
      try {
        let params = { associate_type: this.activeName, page };
        if (this.searchVal) {
          params["keywords"] = this.searchVal;
        }
        let { data, status } = await getMonitorList(params);
        if (status == 200) {
          this[this.activeName + "Data"].data = data.data;
          this[this.activeName + "Data"].pageTotal = data.total *= 1;
          this[this.activeName + "Data"].data.forEach((item) => {
            this.$set(item, "monitor_load", false);
          });
        }
      } catch (error) {
        return false;
      }
    },
    // 搜索
    searchBtn (searchVal) {
      this[this.activeName + "Data"].page = 1;
      this.searchVal = searchVal;
      this.getMonitorList();
    },
    // 切换tabs时请求当前tab内所需数据
    handleTabs (tab) {
      this.getMonitorList();
      setItem("monitor_list_type", this.activeName, "sessionStorage");
    },
    // 分页切换
    changePage (page) {
      this[this.activeName + "Data"].page = page;
      this.getMonitorList(page);
    },
    // 取消监控
    async monitorBtn (id) {
      let listData = this[this.activeName + "Data"].data;
      let ind = listData.findIndex((item) => item.code_id == id);
      listData[ind].monitor_load = true;
      switch (this.activeName) {
        case "company":
          var { status } = await setEpMonitor({keywords:id,monitor_status: 2});
          break;
        case "patent":
          var { status } = await setPatentMonitor({keywords:id,monitor_status: 2});
          break;
        case "trademark":
          var { status } = await setTradeMonitor({keywords:id,monitor_status: 2});
          break;

        default:
          break;
      }
      if (status == 200) {
        this.$message.success("取消监控成功");
        listData[ind].monitor_load = false;
        listData.splice(ind, 1);
      } else {
        this.$message.error("取消监控失败");
        listData[ind].monitor_load = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.monitor-list {
  position: relative;
}
::v-deep .el-tabs.el-tabs--card {
  .el-tabs__content {
    margin-top: 48px;
  }
}
::v-deep .search {
  max-width: 350px;
  height: 36px;
  position: fixed;
  top: 100px;
  z-index: 90;
  margin-left: 318px;
  // .el-input__icon {
  //   line-height: 38px;
  // }
  // .el-input-group__append {
  //   padding: 0 10px;
  //   .el-button {
  //     font-size: 16px;
  //   }
  // }
}
</style>